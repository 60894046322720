var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "divider-demo" } },
    [
      _c("divider-default"),
      _c("divider-text"),
      _c("divider-text-position"),
      _c("divider-color"),
      _c("divider-icon"),
      _c("divider-style"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }