var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Text Position", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can guide the text in 5 ways with property "),
        _c("code", [_vm._v("position")]),
        _vm._v(":"),
      ]),
      _c("vx-list", {
        staticClass: "mt-3",
        attrs: {
          list: [
            "left",
            "left-center",
            "center(default)",
            "right-center",
            "right",
          ],
        },
      }),
      _c(
        "div",
        { staticClass: "op-block mt-5" },
        [
          _c("vs-divider", { attrs: { position: "left" } }, [_vm._v(" Left ")]),
          _c("vs-divider", { attrs: { position: "left-center" } }, [
            _vm._v(" Left-Center "),
          ]),
          _c("vs-divider", { attrs: { position: "center" } }, [
            _vm._v(" Center(default) "),
          ]),
          _c("vs-divider", { attrs: { position: "right-center" } }, [
            _vm._v(" Right-Center "),
          ]),
          _c("vs-divider", { attrs: { position: "right" } }, [_vm._v("Right")]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-divider position="left">Left</vs-divider>\n\n<vs-divider position="left-center">Left-Center</vs-divider>\n\n<vs-divider position="center">Center(default)</vs-divider>\n\n<vs-divider position="right-center">Right-Center</vs-divider>\n\n<vs-divider position="right">Right</vs-divider>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }