var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the color of the divider line with the property "
        ),
        _c("code", [_vm._v("color")]),
        _vm._v(", you can use the main colors or "),
        _c("strong", [_vm._v("RGB")]),
        _vm._v(" and "),
        _c("strong", [_vm._v("HEX")]),
        _vm._v("."),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "op-block mt-5" },
        [
          _c("vs-divider", { attrs: { color: "Default" } }, [
            _vm._v(" Default "),
          ]),
          _c("vs-divider", { attrs: { color: "primary" } }, [
            _vm._v(" Primary "),
          ]),
          _c("vs-divider", { attrs: { color: "success" } }, [
            _vm._v(" Success "),
          ]),
          _c("vs-divider", { attrs: { color: "danger" } }, [
            _vm._v(" Danger "),
          ]),
          _c("vs-divider", { attrs: { color: "warning" } }, [
            _vm._v(" Warning "),
          ]),
          _c("vs-divider", { attrs: { color: "dark" } }, [_vm._v(" Dark ")]),
          _c("vs-divider", { attrs: { color: "rgb(29, 222, 194)" } }, [
            _vm._v(" RGB "),
          ]),
          _c("vs-divider", { attrs: { color: "#ad289f" } }, [_vm._v(" HEX ")]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-divider>Default</vs-divider>\n\n<vs-divider color="primary">Primary</vs-divider>\n\n<vs-divider color="success">Success</vs-divider>\n\n<vs-divider color="danger">Danger</vs-divider>\n\n<vs-divider color="warning">Warning</vs-divider>\n\n<vs-divider color="dark">Dark</vs-divider>\n\n<vs-divider color="rgb(29, 222, 194)">RGB</vs-divider>\n\n<vs-divider color="#ad289f">HEX</vs-divider>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }