var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Style", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can change line's style with the property "),
        _c("code", [_vm._v("border-style")]),
        _vm._v(". The allowed values are equivalent to the "),
        _c("code", [_vm._v("border-style")]),
        _vm._v(" property in CSS."),
      ]),
      _c("vx-list", {
        staticClass: "mt-3 mb-5",
        attrs: { list: _vm.styleTypes },
      }),
      _c(
        "div",
        { staticClass: "op-block" },
        [
          _c(
            "vs-divider",
            { attrs: { "border-style": "dotted", color: "primary" } },
            [_vm._v("dotted")]
          ),
          _c(
            "vs-divider",
            { attrs: { "border-style": "dashed", color: "success" } },
            [_vm._v("dashed")]
          ),
          _c(
            "vs-divider",
            { attrs: { "border-style": "solid", color: "danger" } },
            [_vm._v("solid")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-divider border-style="dotted" color="primary">dotted</vs-divider>\n<vs-divider border-style="dashed" color="success">dashed</vs-divider>\n<vs-divider border-style="solid" color="danger">solid</vs-divider>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }