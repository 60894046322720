import { render, staticRenderFns } from "./DividerText.vue?vue&type=template&id=6b8cc1d4&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b8cc1d4')) {
      api.createRecord('6b8cc1d4', component.options)
    } else {
      api.reload('6b8cc1d4', component.options)
    }
    module.hot.accept("./DividerText.vue?vue&type=template&id=6b8cc1d4&", function () {
      api.rerender('6b8cc1d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/vuesax/divider/DividerText.vue"
export default component.exports