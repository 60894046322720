var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To add an icon within the division we have the property "),
        _c("code", [_vm._v("icon")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "Vuesax uses the Google Material Icons font library by default. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page")]
            ),
            _vm._v("."),
          ]),
          _c("p", [
            _vm._v(
              "FontAwesome and other fonts library are supported. Simply use the icon-pack with fa or fas. You still need to include the Font Awesome icons in your project."
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "op-block mt-5" },
        [
          _c("vs-divider", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-arrow-down",
              position: "left",
            },
          }),
          _c("vs-divider", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-star",
              position: "left-center",
              color: "primary",
            },
          }),
          _c("vs-divider", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-check",
              position: "center",
              color: "success",
            },
          }),
          _c("vs-divider", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-x-circle",
              position: "right-center",
              color: "danger",
            },
          }),
          _c("vs-divider", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-alert-triangle",
              position: "right",
              color: "warning",
            },
          }),
          _c("vs-divider", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-clock",
              position: "center",
              color: "dark",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-divider icon-pack="feather" icon="icon-arrow-down" position="left"></vs-divider>\n\n<vs-divider icon-pack="feather" icon="icon-star" position="left-center" color="primary"></vs-divider>\n\n<vs-divider icon-pack="feather" icon="icon-check" position="center" color="success"></vs-divider>\n\n<vs-divider icon-pack="feather" icon="icon-x-circle" position="right-center" color="danger"></vs-divider>\n\n<vs-divider icon-pack="feather" icon="icon-alert-triangle" position="right" color="warning"></vs-divider>\n\n<vs-divider icon-pack="feather" icon="icon-clock" position="center" color="dark"></vs-divider>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }